import { intersectionObserve } from "./_intersection-observer";
import {showVimeoInLightbox} from "./grensesnitt-lightbox";

var playOnceVideos = document.querySelectorAll('.large-image video.play-once');
intersectionObserve(playOnceVideos, 0, (target, intersectionRatio) => {
    if (intersectionRatio > 0 && target.paused) {
        target.play();
    }
});

$('a.video-play-overlay').click(function(e) {
    e.preventDefault();
    var vimeoId = $(this).data('vimeoId');
    showVimeoInLightbox(vimeoId);
})
setTimeout(function() {
    //showLightbox();
}, 1000);
