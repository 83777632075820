import Swipe from 'swipejs'

var animationLength = 500; // Change this in _grensesnitt-lightbox.scss as well if changed

var $grensesnittLightboxContainer = $('.gl-container');
var $lightboxInner = $grensesnittLightboxContainer.find('.gl-inner');

$('.gl-container .gl-close').click(function (e) {
    e.preventDefault();

    hideLightbox();
});

export function hideLightbox() {
    $grensesnittLightboxContainer.removeClass('fade');

    setTimeout(function () {
        $grensesnittLightboxContainer.removeClass('show');
        $('body').removeClass('noscroll');
        $lightboxInner.html('');
    }, animationLength);
}

export function showLightbox() {
    $grensesnittLightboxContainer.addClass('show');

    setTimeout(function () {
        $grensesnittLightboxContainer.addClass('fade');

        setTimeout(function () {
            $('body').addClass('noscroll');
        }, animationLength);
    }, 10);
}

export function showVimeoInLightbox(vimeoId) {
    showLightbox();

    $lightboxInner.html('<iframe title="vimeo-player" src="https://player.vimeo.com/video/' + vimeoId + '" width="640" height="272" frameborder="0" allowfullscreen></iframe>')
}

$('a[href="#film"]').click(function(e) {
    showVimeoInLightbox(552595111);
})
